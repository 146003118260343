<template>
    <nav class="navbar  navbar-light" style="background-color: #448191">

  <router-link to="/" class="navbar-brand">DP Liburna</router-link>


    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <router-link to="/kurset" class="nav-link">Kurset</router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link to="/Kontakt" class="nav-link">Kontakt</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/ku-gjendemi" class="nav-link">Ku Gjendemi</router-link>
      </li> -->
    
    </ul>
    

</nav>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: flex-start !important;
  a {
      color: white !important;
    }
  .navbar-brand {
    color: white !important;
    margin-left: 10px;


    a {
      color: white !important;
    }
}
}

</style>