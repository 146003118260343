<template>
<div class="card-group">
    <h6>Institucionet e preferuara për punë në Gjermani dhe për teste të licencuara</h6>
  <div class="card mt-3  ">
    <img class="card-img-top" :src="require('../assets/logo1-senior.jpg')" >
    <div class="card-body ">
      <a href="https://www.senioreneinrichtungen.info/">
      <h5 class="card-title">Senioreneinrichtungen des Landkreises Würzburg-Deutschland</h5>
      <p class="card-text">Die Senioreneinrichtungen des Landkreises Würzburg bieten der Bevölkerung in der Region ein breitgefächertes Angebot im Bereich der Altenpflege  </p>
    </a>
    </div>
  </div>
  <div class="card mt-3 ">
    <img class="card-img-top" :src="require('../assets/logo-goethe.png')">
    <div class="card-body ">
      <a href="https://goethe-ks.org/">
      <h5 class="card-title">Goethe Zentrum Prishtina</h5>
      <p class="card-text">Goethe-Zentrum në Prishtinë është një qendër e re dhe në rritje dinamike, e cila mbështetet në mënyrë aktive nga Instituti Goethe</p>
    </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: 'Partners'
}
</script>



<style lang="scss" scoped>
.card-group {
        display: flex;
        flex-direction: column;
        .card {
            border: 1px solid lightgray;
            img {
            margin-top: 10px;
        }
        a {
          text-decoration: none;
          color: black;

        }
        .card-body {
            margin-top: 10px;
            background-color: lightgray;
            &:hover {
              background-color: lightblue;
            }
        }
        }
    
    }
</style>