<template>
  <div id="app">

    
    <div class="one row">
      <Identity class="col-lg-2 order-lg-last "/>
      <Entry class="col-lg-10 order-lg-first" />
      
    </div>
    <div class="two row">
      <div class="staff row">
        
        <div class="col-lg-2 col-sm-4 team1"><StaffItem name="Elhami Ismaili" photo="elhami.jpg" email="b.liburna@gmail.com" :profesion="['Perkthimet Gjermanisht-Shqip', 'Kurset A1 dhe A2', 'Kurset B1 dhe B2']" /></div>
        <div class="col-lg-2 col-sm-4 team2"><StaffItem name="Daniela Weber-Mahmuti" photo="daniela.jpg" email="danielawm@hotmail.de" :profesion="['Kurseve per infermier/e B1 + B2', ' Kurset Konverzacion A + B']" /></div>
        <div class="col-lg-2 col-sm-4 team3"><StaffItem name="Ganimete Kurteshi-Ismaili" photo="gane.jpg" email="ganadpl.78@gmail.com" :profesion="['Çështje Juridike', 'Pergaditjet per provimet e gjuhes prej A1 deri ne B2']" /></div>
        
        <div class="news col-lg-2">
        <Notifications class="notifications"/>
        </div>
        <div class="col-lg-3">
          <Partners class="partners"/>
        </div>
      </div>
      
    </div>
    <div class="three">
      
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Entry from './components/Entry.vue';
import StaffItem from './components/StaffItem.vue'
import Identity from './components/Identity.vue'
import Notifications from './components/Notifications.vue'
import Partners from './components/Partners.vue'

export default {
  name: 'App',
  components: {
    Entry,
    StaffItem,
    Identity,
    Notifications,
    Partners
  }
}
</script>

<style lang="scss" scoped>
#app {

  .one {
    margin: 20px;
    display: flex;
    flex-direction: row;
  }
  .two {
    margin: 20px;
    .staff {
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      height: 70%;
      .team1, .team2, .team3 {
        margin-left: -50px;
      }

      .notifications {
        @media screen and (min-width: 992px) {
          margin-right: -60px;
        }
      }

      @media screen and (max-width: 576px) {
        padding: 10px;
        .team1, .team2, .team3 {

          padding: 10px !important;
          margin: 10px !important;

        }
      }
    }
    .news {
      @media screen and (max-width: 992px) {
        margin-top: 50px;
    }
    }
  }
}
</style>