<template>
  <div id="app">
    <div class="menu">
      <Header />
    </div>
    <router-view> </router-view>
    
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Header from './components/Header.vue'


export default {
  name: 'App',
  components: {
    Header,

  }
}
</script>

<style lang="scss" scoped>

</style>