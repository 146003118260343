<template>
<div class="list-group">
  <h6>Njoftime</h6>
  <a href="#/kurset" class="list-group-item list-group-item-action flex-column align-items-start list-group-item-info">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">Informacion rreth regjistrimit të kursit tonë</h5>
    </div>
    <p class="mb-1">Konsultimet konktrete dhe sigurimi i vendit në një nga kurset tona bëhet personalisht me prezencën tuaj në njërin nga lokacionet tona.</p>
  </a>
  <a href="https://prufungen.goethe-ks.org/start.nspx" class="list-group-item list-group-item-action flex-column align-items-start list-group-item-primary">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">Paraqitja e provimeve tek Goethe Institut</h5>
    </div>
    <p class="mb-1">Këtu mund të drejtoheni për paraqitjen e provimeve tek Goethe Institut prej nivelit A1 deri në C1</p>
  </a>
  <a href="https://goethe-ks.org/goethe-institut/" class="list-group-item list-group-item-action flex-column align-items-start list-group-item-secondary">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">Rezultatet e Provimeve tek Goethe Institut</h5>
    </div>
    <p class="mb-1">Këtu mund të drejtoheni rreth rezultateve të fundit për provimet e mbajtura në Goethe Institut</p>
  </a>
  <div class="list-group-item list-group-item-action flex-column align-items-start list-group-item-info">
    <div class="list-movies">
      <h5 class="mb-1">Kanalet e preferuara për mësimin e gjuhës gjermane.</h5>
        <ul>
          <li><a href="https://www.ardmediathek.de/">ARD Mediathek</a></li>
          <li><a href="https://www.zdf.de/">ZDF Mediathek</a></li>
          <li><a href="https://www.n-tv.de/">n-tv</a></li>
        </ul>
    </div>
  </div>
</div>
</template>

<script>
export default {
    name: 'Notifications'
}
</script>

<style lang="scss" scoped>

</style>
