<template>
    <div class="wrapper">
        <div class="map"></div>
        <div class="logo">
            <img :src="require('../assets/logo.jpg')" class="rounded float-right" style="max-width: 150px">
        </div>
        <div class="info">
            <address>
                <strong>Deutschpunkt Liburna</strong><br>
                Te rruga B. i Pavarësisë Nr. 1/4<br>
                60000 Gjilan <br>
                Kosovë <br>
                +383 44 212 596 <br>
                <a href="mailto:b.liburna@gmail.com">b.liburna@gmail.com</a>
            </address>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    @media screen and (max-width: 992px) {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
        margin-left: -10px;
    }

    .logo {
        padding-top: 20px;
    }
    .info {
        padding: 20px;
        font-size: 1rem
    }
}
</style>