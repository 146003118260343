<template>
    <div class="card" >
        <img :src="(require('../assets/' + photo))" alt="" class="card-img-top img-fluid img-thumbnail">
        <div class="card-body">
            <h5 class="card-title">{{name}}</h5>
            <div class="card-text">
                <h6>Kontaktoni rreth:</h6>
                <ul>
                    <li v-for="(item, index) in profesion" :key="index">{{item}}</li>
                </ul>
            </div>
            <p>Email: <i>{{email}}</i></p>
            <p></p>
            <a :href="`mailto:${email}`" class="btn btn-primary">Contact</a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        photo: {
            type: String,
            default: '',
        },
        profesion: {
            type: Array,
            default: () => []
        },
        email: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;};
    ul {

        list-style: square;
        padding: 0;
        li {
            font-size: 12px;
            font-weight: 500;
            font-style: italic;
            color: #36454F;
        }
    }

</style>