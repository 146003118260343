import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kurset',
    name: 'kurset',
    component: () => import('@/components/Kurset.vue')
  }
  

]

const router = new VueRouter({
  routes
})

export default router
